import { createStore } from "vuex";
import auth from "./auth/auth.module.js";





import user from "./mainData/user.module.js";


import notification from "./notification.js";
import Firebase from "./firebaseNotification/Firebase.module.js";
import users from "./users.module.js";
import coustomerTransactions from "./coustomerTransactions/coustomerTransactions.module.js";
import SearchUsersHistories from "./mainData/SearchUsersHistories.module.js";
import referUsers from "./mainData/referUsers.js";
import realRideDetails from "./realRideDetails.module.js";
import routes from "./routes/routes.module.js";
import routesCategory from "./routes/routesCategory.js";
import usersBooking from "./mainData/usersBooking.js";
import contractorTransaction from "./contractorTransaction/contractorTransaction.js";
import contractorInvoice from "./contractorInvoice.module.js"
import UsersNotifications from "./UsersNotifications.js"

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
  
    users,
    
    user,
  
    notification,

    Firebase,
    coustomerTransactions,
    SearchUsersHistories,
    referUsers,
    realRideDetails,
    routes,
    routesCategory,
    usersBooking,
    contractorTransaction,
    contractorInvoice,
    UsersNotifications
  },
});
