import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    realRideDetails: [],
    DetailsByDate: [],
    RealRidesTransaction: [],
    RideCashCollections: [],
    OutOfWalletByReal: [],
    InWalletByReal: [],
    numOfRealRidesDetails:[],
    RealRidesTransactionsDaily:[],
    PrivateBusRealRidesTransactionsDaily:[]

  },
  getters: {
    getrealRideDetails(state) {
      return state.realRideDetails;
    }, getDetailsByDate(state) {
      return state.DetailsByDate;
    }, getRealRidesTransaction(state) {
      return state.RealRidesTransaction;
    },getRideCashCollections(state) {
      return state.RideCashCollections;
    },getOutOfWalletByReal(state) {
      return state.OutOfWalletByReal;
    },getInWalletByReal(state) {
      return state.InWalletByReal;
    },
    getNumOfRealRidesDetails(state){
      return state.numOfRealRidesDetails;
    },
    getRealRidesTransactionsDaily(state){
      return state.RealRidesTransactionsDaily;
    },
    getPrivateBusRealRidesTransactionsDaily(state){
      return state.PrivateBusRealRidesTransactionsDaily;
    }
  },
  mutations: {
    SetrealRideDetails(state, data) {
      state.realRideDetails = [];
      state.realRideDetails = data.map(ride => ({
        ...ride,
        rideDate2: ride.rideDate,
        rideDate: new Date(ride.rideDate),
        totalExpenses: Number(ride.totalExpenses),
        totalRevenues: Number(ride.totalRevenues),

      }));
    },
     SetsDetailsByDate(state, data) {
      state.DetailsByDate = [];
      state.DetailsByDate = data.map(ride => ({
        ...ride,
        rideDate: new Date(ride.rideDate),

      }));
    } ,SetRealRidesTransaction(state, data) {
      state.RealRidesTransaction = [];
      state.RealRidesTransaction = data.map(ride => ({
        ...ride,
        rideDate: new Date(ride.rideDate),

      }));
    }
    ,SetRideCashCollections(state, data) {
      state.RideCashCollections = [];
      state.RideCashCollections = data;
    },SetOutOfWalletByReal(state, data) {
      state.OutOfWalletByReal = [];
      state.OutOfWalletByReal = data;
    },SetInWalletByReal(state, data) {
      state.InWalletByReal = [];
      state.InWalletByReal = data;
    },
    SetNumOfRealRideDetails(state,data){
      state.numOfRealRidesDetails=[];
      state.numOfRealRidesDetails=data;
    } ,SetRealRidesTransactionsDaily(state,data){
      state.RealRidesTransactionsDaily=[];
      state.RealRidesTransactionsDaily=data;
    },
    SetPrivateBusRealRidesTransactionsDaily(state,data){
      state.PrivateBusRealRidesTransactionsDaily=[];
      state.PrivateBusRealRidesTransactionsDaily=data;
    }
  },
  actions: {
    getNumOfRealRidesDetails({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getNumOfRealRidesDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetNumOfRealRideDetails", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getrealRideDetails({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetrealRideDetails", res.data);
          })
          .catch((err) => console.log(err));
      });
    },



    getRealRidesDetailsByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesDetailsByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetsDetailsByDate", res.data);
          })
          .catch((err) => console.log(err));
      });
    },

    getRealRidesTransactionByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesTransactionByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetRealRidesTransaction", res.data);
          })
          .catch((err) => console.log(err));
      });
    },   
    getRealRidesTransactionsDaily({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesTransactionsDailyByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetRealRidesTransactionsDaily", res.data);
          })
          .catch((err) => console.log(err));
      });
    },   
    GetPrivateBusRealRidesTransactionsDailyByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/GetPrivateBusRealRidesTransactionsDailyByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetPrivateBusRealRidesTransactionsDaily", res.data);
          })
          .catch((err) => console.log(err));
      });
    }, 
    getRealRideCashCollections({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRideCashCollections`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetRideCashCollections", res.data);
          })
          .catch((err) => console.log(err));
      });
    },   
    
    getOutOfWalletByRealId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getOutOfWalletByRealId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetOutOfWalletByReal", res.data);
          })
          .catch((err) => console.log(err));
      });
    },  
    getInWalletByRealId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getInWalletByRealId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetInWalletByReal", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
  },
};
