import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    referUsers: [],
   
  },
  getters: {
    getreferUsers(state) {
      return state.referUsers;
    }
  },
  mutations: {
    SetreferUsers(state, data) {
      state.referUsers = [];
      state.referUsers = data
    }
  },
  actions: {
    getreferUsers({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `referUsers/getReferUsers`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetreferUsers", res.data);
        })
        .catch((err) => console.log(err));});
    },   
   
  },
};
