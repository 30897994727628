import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    SearchUsersHistories: [],
    numOfSearchUsers:[],
  },
  getters: {
    getSearchUsersHistories(state) {
      return state.SearchUsersHistories;
    },
    getNumOfSearchUsers(state){
      return state.numOfSearchUsers
    }
  },
  mutations: {
    SetSearchUsersHistories(state, data) {
      state.SearchUsersHistories = [];
      state.SearchUsersHistories = data.map(ride => ({
        ...ride,
        searchTime: new Date(ride.searchTime),
      
      }));
    },
    SetNumOfSearchUsers(state,data){
      state.numOfSearchUsers=[];
      state.numOfSearchUsers=data;

    }
  },
  actions: {
    getSearchUsersHistories({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `searchUsersHistories/getSearchUsersHistories`,
         data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetSearchUsersHistories", res.data);
        })
        .catch((err) => console.log(err));});
    },   
    getNumOfSearchUsers({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `searchUsersHistories/getNumOfSearchUsers`,
         data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetNumOfSearchUsers", res.data);
        })
        .catch((err) => console.log(err));});
    }, 
   
  },
};
