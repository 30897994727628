import {
  createRouter,
  createWebHashHistory,
  // createWebHistory,
} from "vue-router";
// import jwt_decode from 'jwt-decode';

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("JwtToken")) {
    next();
    return;
  }
  next("/");
};

const checkGroupPermission = (requiredGroupIds) => {
  return (to, from, next) => {
    const GID = localStorage.getItem("GID");
    if (GID) {
      const groupId = Number(GID);
      if (requiredGroupIds.includes(groupId)) {
        next();
      } else {
        next("/"); // Redirect to home or an error page
      }
    } else {
      next("/"); // Redirect to home or login page if token is missing
    }
  };
};



const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/loginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    beforeEnter: ifAuthenticated,
    component: () => import("../views/HomeView.vue"),
    children: [


      {
        path: "/users",
        name: "users",
        component: () => import("../views/MainData/usersView.vue"),
        beforeEnter: checkGroupPermission([1,15,16
        ]) // Example group IDs with access

      },
      {
        path: "/usersDetails",
        name: "usersDetails",
        component: () => import("../views/MainData/usersDetailsView.vue"),
        //beforeEnter: checkGroupPermission([1]) // Example group IDs with access

      },
      {
        path: "/bookingCount", // Define route with parameter ':id'
        name: "bookingCount",
        component: () => import("../views/bookingCountView.vue"),
        beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access
      },
      {
        path: "/changePassword", // Define route with parameter ':id'
        name: "changePassword",
        component: () => import("../views/changePasswordView.vue"),

      },
      {
        path: "/UserbyGropingDate", 
        name: "UserbyGropingDate",
        component: () =>
          import(
            "../components/MainData/users/UserbyGropingDate/UserbyGropingDateTable.vue"
          ),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access

      },
      {
        path: "/usersOperationsTable", 
        name: "usersOperations",
        component: () =>
          import(
            "../components/admin/usersOperationTable.vue"
          ),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access

      },{
        path: "/usersSearch", 
        name: "user Search",
        component: () =>
          import(
            "../components/MainData/users/usersTableById.vue"
          ),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access

      },
      {
        path: "/SearchUsersHistories",
        name: "Search Users Histories",
        component: () =>
          import("../views/SearchUsersHistories/SearchUsersHistories.vue"),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access
      },
      {
        path: "/realRideDetails",
        name: "RealRide Details",
        component: () =>
          import("../views/realRideDetailsView.vue"),
          beforeEnter: checkGroupPermission([1,13,16]) // Example group IDs with access
      },
      {
        path: "/routesDetails",
        name: "routes Details",
        component: () =>
          import("../views/routesDetailsView.vue"),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access
      },
      {
        path: "/RidesTransaction",
        name: "RidesTransaction",
        component: () =>
          import("../views/realRidesTransaction.vue"),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access
      } ,
       {
        path: "/contractorTransaction",
        name: "contractorTransaction",
        component: () =>
          import("../views/contractorTransaction/contractorTransactionView.vue"),
          beforeEnter: checkGroupPermission([1,15,16]) // Example group IDs with access
      },
      {
        path: "/contractorInvoice/:id", // Define route with parameter ':id'
        name: "contractorInvoice",
        component: () =>
          import("../components/contractorTransaction/contractorInvoice.vue"),
        beforeEnter: checkGroupPermission([1,15,16]), // Example group IDs with access
      },
      {
        path: "/usersNotifications", // Define route with parameter ':id'
        name: "usersNotifications",
        component: () =>
          import("../views/usersNotifications.vue"),
        beforeEnter: checkGroupPermission([1,15,16]), // Example group IDs with access
      },{
        path: "/RealRidesTransactionsDaily", // Define route with parameter ':id'
        name: "RealRidesTransactionsDaily",
        component: () =>
          import("../views/RealRidesTransactionsDaily.vue"),
        beforeEnter: checkGroupPermission([1,15,16]), // Example group IDs with access
      },
      {
        path: "/PrivateBusRealRidesTransactionsDaily", // Define route with parameter ':id'
        name: "PrivateBusRealRidesTransactionsDaily",
        component: () =>
          import("../views/privateBusRealRideTransactioinsDailyView.vue"),
        beforeEnter: checkGroupPermission([1,15,16]), // Example group IDs with access
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
