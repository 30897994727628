import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';  
import 'primeicons/primeicons.css';      
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';  
import "primevue/resources/themes/md-light-indigo/theme.css";
// import "primevue/resources/themes/md-dark-indigo/theme.css";
import './assets/fontawesome-free-6.4.2-web/css/all.css'
import 'primeflex/primeflex.css';  
import ToastService from 'primevue/toastservice';
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import  DataTable  from 'primevue/datatable';
import  Paginator  from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ColumnGroup from 'primevue/columngroup';   
import Row from 'primevue/row';    
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import PickList from 'primevue/picklist';
import Timeline from 'primevue/timeline';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Menu from 'primevue/menu';
import Tooltip from 'primevue/tooltip'
import InputSwitch from 'primevue/inputswitch';
import TreeTable from 'primevue/treetable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Chips from 'primevue/chips';
import Tree from 'primevue/tree';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import MultiSelect from 'primevue/multiselect';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(VueSidebarMenu);
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);

app.component('InputGroup ', InputGroup );
app.component('InputGroupAddon ', InputGroupAddon );
app.component('TabView ', TabView );
app.component('TabPanel ', TabPanel );
app.component('Tree ', Tree );
app.component('Chips ', Chips );
app.component('IconField ', IconField );
app.component('InputIcon', InputIcon);
app.component('TreeTable ', TreeTable );
app.component('Button', Button);
app.component('Card', Card);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Password', Password);
app.component('DataTable', DataTable);
app.component('Paginator', Paginator);
app.component('Dropdown', Dropdown);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Toast', Toast);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('PickList', PickList);
app.component('Timeline', Timeline);
app.component('Textarea', Textarea);
app.component('Checkbox', Checkbox);
app.component('Calendar', Calendar);
app.component('Menu', Menu);
app.component('InputSwitch', InputSwitch);
app.component('MultiSelect', MultiSelect);



app.mount('#app');