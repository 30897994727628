import api from '../api';
/* eslint-disable */
export default {
    namespaced: true,
    state: {
       
    },
    getters: {
       
    },
    mutations: {
      
    },
    actions: {
       
        addNotificationsToUsers({ commit },data) {
            return new Promise((resolve, rej) => {
              api()
                .post(
                  `UsersNotifications/addNotificationsToUsers`,
                  data,
                  {
                    headers: {
                      Authorization: localStorage.getItem("JwtToken"),
                    },
                  }
                )
                .then((res) => {
                  resolve(res);
                  
                })
                .catch((err) => console.log(err));
            });
          },
     
        


    }
}