import api from "../../api";
// import axios from "axios";
import axios from 'axios';

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,
  state: {
    routes: [],
    RoutesDetails: [],
    StopsDetails: [],
    DetailsByTime: [],

  },
  getters: {
    getRoutes(state) {
      return state.routes;
    },getRoutesDetails(state) {
      return state.RoutesDetails;
    },getNumOfRoutes(state){
      return state.numOfRoutes;
    },
    getRouteStopsDetails(state) {
      return state.StopsDetails;
    },getRouteDetailsByTime(state) {
      return state.DetailsByTime;
    },
  },
  mutations: {
    SetRoutes(state, data) {
      state.routes = [];
      state.routes = data;
    },
    SetNumOfRoutes(state,data){
      state.numOfRoutes=[];
      state.numOfRoutes=data;
    },
    SetRoutesDetails(state, data) {
      state.RoutesDetails = [];
      state.RoutesDetails = data.map(rout => ({
        ...rout,
        totalDummyRide: Number(rout.totalDummyRide),
            totalBooking: Number(rout.totalBooking),
            routeStopsCount: Number(rout.routeStopsCount),
            totalRealRies: Number(rout.totalRealRies),

      }));;;
    },  
    SetRoutesStopsDetails(state, data) {
      state.StopsDetails = [];
      state.StopsDetails = data;
    }, SetDetailsByTime(state, data) {
      state.DetailsByTime = [];
      state.DetailsByTime = data;
    },
  },
  actions: {
    fetchAllRoutes({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `routes/get_all_routes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetRoutes", res.data);
          resolve()
        })
        .catch((err) => {

          console.log(err)
        });
      })
    },
    getNumOfRoutes({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `routes/getNumOfRoutes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetNumOfRoutes", res.data);
          resolve()
        })
        .catch((err) => {

          console.log(err)
        });
      })
    },
    getrouteDetails({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `routes/getrouteDetails`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetRoutesDetails", res.data);
          resolve()
        })
        .catch((err) => {

          console.log(err)
        });
      })
    },
    GetRouteDetailsByTime({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `routes/GetRouteDetailsByTime`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetDetailsByTime", res.data);
          resolve()
        })
        .catch((err) => {

          console.log(err)
        });
      })
    },
    getrouteStopsDetails({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `routes/getrouteStopsDetails`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetRoutesStopsDetails", res.data);
          resolve()
        })
        .catch((err) => {

          console.log(err)
        });
      })
    },
    fetchRouteById({  },id) {
      return new Promise((resolve, rej) => {
        
      api()
        .post(
          `routes/get_all_route_by_id`,
          {routeId: id},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {   
          resolve(res)
        })
        .catch((err) => console.log(err));
      })
    },

    addRouteData(
      { dispatch },
      { name, category_id, from_area, to_area, status, estimatedTime,baseFair}
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `routes/add_route`,
            {
              name: name,
              category_id: category_id,
              from_area: from_area,
              to_area: to_area,
              status: status,
              estimatedTime: estimatedTime,
              baseFair:baseFair
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editRoute(
      { dispatch },
      { id, name, category_id, from_area, to_area, status, estimatedTime ,baseFair}
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `routes/edite_route`,
            {
              id: id,
              name: name,
              category_id: category_id,
              from_area: from_area,
              to_area: to_area,
              status: status,
              estimatedTime: estimatedTime,
              baseFair:baseFair
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteRoute({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `routes/delte_route`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    addStopsToRoute(
      { dispatch },
      { routeId, stops, estimatedTimeSec, totalDistanceM ,getRouteRes}
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `routes/add_stops_to_route`,
            {
              routeId: routeId,
              stops: stops,
              estimatedTime: estimatedTimeSec,
              totalDistance: totalDistanceM,
              getRouteRes:getRouteRes
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getRouteByGoogle({}, { origin, destination, waypoints, travelMode }) {
      return new Promise((resolve, rej) => {
        axios
          .get("https://maps.googleapis.com/maps/api/directions/json", {
            params: {
              origin: origin,
              destination: destination,
              waypoints: waypoints,
              travelMode: travelMode,
              key: "AIzaSyAW8fSRM_RWI1vTu8MOyKmviSivTDz0Mq8",
            },
          })
          .then((res) => {
            resolve(res);
          }).catch((err) => console.log(err));
      });
    },

    getSearchByGoogle({}, query) {
      return new Promise((resolve, rej) => {
        axios
          .get("https://maps.googleapis.com/maps/api/place/autocomplete/json", {
            params: {
              input: query,

              key: "AIzaSyAW8fSRM_RWI1vTu8MOyKmviSivTDz0Mq8",
            },
          })
          .then((res) => {
            resolve(res);
          }).catch((err) => console.log(err));
      });
    },


  },
};
